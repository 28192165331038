<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>SMS Sender Id</h5>
            </div>
            <div class="card">
                <div style="position: relative" v-if="!showLoader">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <DataTable :value="CompaniesSenderId" :lazy="true" :paginator="true" :rows="30"
                                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)"
                                class="p-datatable-users" data-key="tg1" :rowHover="true"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <Toolbar>
                                    <template v-slot:right>
                                        <Button label="Add New SMS Sender Id" icon="pi pi-plus" @click="onAddCompany" />
                                    </template>
                                </Toolbar>

                                <template #empty> No data found. </template>

                                <template #loading> Loading data. Please wait... </template>
                                <Column header="Sr." headerStyle="width: 4%">
                                    <template #body="{ index }">
                                        <div>{{ genrateSrNo(index) }}</div>
                                    </template>
                                </Column>
                                <Column header="Company Name" field="tg3" headerStyle="width: 20%"></Column>
                                <Column header="Client Name" field="tg11" headerStyle="width: 25%">
                                    <template #body="{ data: { tg11 } }">
                                        <div>
                                            <span v-if="tg11 != '' && tg11 != null && tg11 != '0'">
                                                <span v-for="(value, index) in tg11.split(',')"
                                                    :key="index">
                                                    <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts"
                                                        :label="value" />
                                                </span>
                                            </span>
                                            <span v-else>-</span>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Principle Entity Id" field="tg6" headerStyle="width: 22%"></Column>
                                <Column header="Sender Id Regi No." field="tg7" headerStyle="width: 22%"></Column>
                                <Column header="Operator" field="tg9" headerStyle="width: 15%"></Column>

                                <Column header="Sender Id" headerStyle="width: 11%">

                                    <template #body="{ data }">
                                        <span style="text-transform: uppercase;"> {{ data.tg4 }}</span>
                                    </template>
                                </Column>

                                <Column header="Status" headerStyle="width: 8%">

                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">
                                            <div v-if="data.tg5 == 1"> Active </div>
                                            <div v-else> Inactive</div>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Action" headerStyle="width: 8%">

                                    <template #body="{ data }">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                            @click="onEditCompany(data)" />
                                    </template>
                                </Column>
                            </DataTable>
                            <!-- <Toast /> -->
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add/Edit Company Dialog starts here -->
    <Dialog v-model:visible="isDialogOpen" :style="{ width: '800px' }" :modal="true" class="p-fluid"
        :header="dialogHeader">
        <div style="position: relative">
            <div class="p-pt-2 p-px-3">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <label for="principleentid">
                            Principle Entity Id
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <InputText id="principleentid" class="p-text-capitalize" v-model.trim="principle_entityID"
                            required="true" maxlength="20" autofocus
                            :class="{ 'p-invalid': submitted && !principle_entityID }" />
                        <small class="p-invalid p-error" v-if="v$.principle_entityID.$error">{{
        v$.principle_entityID.$errors[0].$message
    }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="company">
                            Company
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <Dropdown id="company" v-model="company" :options="companyList" optionLabel="label"
                            placeholder="Select company" :class="{ 'p-invalid': isSubmitted && !company }"
                            :filter="true" appendTo="body"></Dropdown>
                        <small class="p-invalid p-error" v-if="v$.company.$error">{{
        v$.company.$errors[0].$message }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="senerId">
                            Sender Id
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <InputText id="senerId" v-model.trim="senerId" style="text-transform: uppercase;"
                            required="true"  maxlength="15" autofocus
                            :class="{ 'p-invalid': isSubmitted && !senerId }" />
                        <small class="p-invalid p-error" v-if="v$.senerId.$error">{{
        v$.senerId.$errors[0].$message
    }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="senderregid">
                            Sender ID Registration No.
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <InputText id="senderregid" class="p-text-capitalize" v-model.trim="senderregid" required="true"
                            maxlength="20" autofocus :class="{ 'p-invalid': isSubmitted && !senderregid }" />
                        <small class="p-invalid p-error" v-if="v$.senderregid.$error">{{
        v$.senderregid.$errors[0].$message
    }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="dltoperator">
                            DLT Operator
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <Dropdown id="dltoperator" v-model="dltoperator" :options="DLTOperatorList" optionLabel="label"
                            :class="{ 'p-invalid': isSubmitted && !dltoperator }" placeholder="Select ..."
                            :filter="true" appendTo="body"></Dropdown>
                        <small class="p-invalid p-error" v-if="v$.dltoperator.$error">{{
        v$.dltoperator.$errors[0].$message
    }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="client">
                            Client
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <AutoComplete class="p-fluid" id="client" v-model="client_fks" multiple
                            :suggestions="clientList" appendTo="body" @complete="searchClients"
                            placeholder="Select client" field="label" dropdown />
                        <small class="p-invalid p-error" v-if="v$.client_fks.$error">{{
        v$.client_fks.$errors[0].$message
                            }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label>Status <span class="p-invalid p-error"> *</span></label>
                        <div class="p-field-radiobutton">
                            <RadioButton id="status_active" name="option" v-bind:value="1" v-model="statusType"
                                :checked="statusType == 1" />
                            <label for="status_active" class="p-mr-2">Active</label>
                            <RadioButton id="status_Inactive" name="option" v-bind:value="0" v-model="statusType"
                                :checked="statusType == 0" />
                            <label for="status_Inactive" class="p-mr-2">Inactive</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Submit" :icon="!showLoader ? 'pi pi-check' : 'pi pi-spin pi-spinner indigo-color'"
                class="p-button-text p-ml-auto" @click="addCompany" style="width: 90px;"></Button>
            <!-- <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button> -->
        </template>
    </Dialog>
    <!-- Add/Edit Company Dialog ends here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers, maxLength } from '@vuelidate/validators';

export default {
    data() {
        return {
            CompaniesSenderId: [],
            v$: useValidate(),
            isDialogOpen: false,
            isSubmitted: false,
            showLoader: false,
            loading: false,
            page_no: 0,
            totalRecords: 0,
            validation: {},
            companyList: [],
            senerId: '',
            statusType: 0,
            company: '',
            rowId: 0,
            senderregid: '',
            principle_entityID: '',
            dltoperator: '',
            DLTOperatorList: [
                { label: 'Jio', value: 1 },
                { label: 'Airtel', value: 2 },
                { label: 'Videocon', value: 3 },
                { label: 'BSNL', value: 4 },
            ],
            clientList: null,
            client_fks: [],
        };
    },
    validations() {
        this.validation = {
            company: { required: helpers.withMessage('Please select company name', required) },
            senerId: { required: helpers.withMessage('Please enter sender Id', required) },
            senderregid: { required: helpers.withMessage('Please enter sender registartion Id', required) },
            principle_entityID: { required: helpers.withMessage('Please enter principle entity ID', required) },
            dltoperator: { required: helpers.withMessage('Please enter DLT Operator', required) },
            client_fks: { required: helpers.withMessage('Please select client', required), maxLength: helpers.withMessage('Allow to select only 5 client', maxLength(5)) },
        };
        return this.validation;
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getClientListOnly();
        this.getCompaniesSenderIdList();
        this.getcompanyListOnly();
    },
    methods: {
        searchClients(e) {
            this.clientList = this.clientList.flatMap(d => d.label.toLowerCase().includes(e.query) ? [{ label: d.label, value: d.value }] : []);
            if (this.clientList.length === 0) {
                this.getClientListOnly();
            }
        },

        onAddCompany() {
            this.client_fks = [];
            this.v$.$reset();
            this.xyz = "";
            /** Open popup of add Company gode goes here */
            this.dialogHeader = 'Add SMS sender Id';
            this.isDialogOpen = true;
            this.company = '';
            this.rowId = 0,
                this.senerId = '';
            this.statusType = 0;
            this.senderregid = '';
            this.principle_entityID = '';
            this.dltoperator = '';
        },
        onEditCompany(data) {
            this.client_fks = [];
            this.dialogHeader = "Edit SMS sender Id";
            this.isDialogOpen = true;
            this.rowId = data.tg1;
            let tg2 = this.companyList.filter(function (item) {
                return item.value == data.tg2;
            });
            if (tg2.length > 0) {
                this.company = tg2[0];
            }
            if (data.tg10) {
                this.client_fks = this.selectedValues({ label: data.tg11, value: data.tg10 });
            }
            this.senerId = data.tg4;
            this.statusType = data.tg5;
            this.principle_entityID = data.tg6;
            this.senderregid = data.tg7;
            let tg8 = this.DLTOperatorList.filter(function (item) {
                return item.value == data.tg8;
            });
            if (tg8.length > 0) {
                this.dltoperator = tg8[0];
            }

        },

        getClientListOnly(e) {
            this.ApiService.getClientListOnly(e).then((data) => {
                if (data.status == 200) {
                    this.clientList = data.data;
                } else {
                    this.clientList = null;
                }
            });
        },

        async getCompaniesSenderIdList(params) {
            try {
                const result = await this.ApiService.getCompaniesSenderIdList({ ...params })
                this.loading = false;
                if (!result.success) {
                    return this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
                }
                this.CompaniesSenderId = result.data;
                this.totalRecords = result.count;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });

            }
        },
        getcompanyListOnly() {
            this.ApiService.getcompanyListOnly().then((data) => {
                if (data.status == 200) {
                    this.companyList = data.data;
                } else {
                    this.companyList = '';
                }
            });
        },
        async addCompany() {
            this.isSubmitted = true;
            this.v$.$validate();
            if (this.v$.error) return;

            let tg10 = this.client_fks.map(d => d.value).join();
            let tg11 = this.client_fks.map(d => d.label).join();

            let formData = new FormData();
            formData.append("tg1", this.rowId);
            formData.append("tg2", this.company.value);
            formData.append("tg3", this.company.label);
            formData.append("tg4", this.senerId);
            formData.append("tg5", this.statusType);
            formData.append("tg6", this.principle_entityID);
            formData.append("tg7", this.senderregid);
            formData.append("tg8", this.dltoperator.value);
            formData.append("tg9", this.dltoperator.label);
            formData.append("tg10", tg10);
            formData.append("tg11", tg11);

            if (!this.v$.$error) {
                this.loading = this.showLoader = true;
                let result = await this.ApiService.createUpdateCompaniesSenderId(formData)
                this.loading = this.showLoader = this.isDialogOpen = false;
                if (result.success) {
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: result.message, life: 3000 });
                    this.getCompaniesSenderIdList();
                    this.CompanyId = 0;
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Company not added. Please try again', life: 3000 });
                }
            }
        },
    }

};
</script>

<style scoped></style>
